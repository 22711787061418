import { useEffect } from "react"
import Cars from "../Cars/Cars"
import Offer from "../Services/Offer"
import Services from "../Services/Services"
import Slider from "../Slider/Slider"
import Aos from "aos"
import './Home.scss'
import Weare from "../Weare/Weare"
import JoinSection from "../JoinSection/JoinSection"
import Pictograms from "../Pictograms/Pictograms"


const Home = () => {
    const AOS = Aos

    useEffect(()=>{
        AOS.init();
    }, [])
    
    return (
        <main className="main">
            <Slider />
            <Weare />
            <Offer />
            <Pictograms />
            <Services />
            <Cars />
            <JoinSection />
        </main>
    )
}

export default Home