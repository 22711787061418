// import logo from './../../../assets/img/main_logo.png'
import logo from './../../../assets/img/logo.png'
import './Header.scss'
import Menu from './Menu/Menu'
import Lang from './Lang/Lang'

const Header = () => {
    return (
        <header className='header'>
            <div className='header__logo'>
                <img src={logo} alt='logo' className='header__logo--img' />
            </div>
            <div className='container d-none d-lg-flex  align-items-center'>
                <Menu />
                <Lang />
            </div>
        </header>
    )
}

export default Header