import './Slider.scss'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ContactBtn from '../Common/Header/ContactBtn';
import SocNetwork from './SocNetwork';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Autoplay} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import slider1 from './../../assets/img/slider/1.jpg'
import slider2 from './../../assets/img/slider/2.jpg'
import slider3 from './../../assets/img/slider/3.jpg'
import slider4 from './../../assets/img/slider/4.jpg'
import slider5 from './../../assets/img/slider/5.jpg'
import slider6 from './../../assets/img/slider/6.jpg'
import slider7 from './../../assets/img/slider/7.jpg'
import slider8 from './../../assets/img/slider/8.jpg'
import slider9 from './../../assets/img/slider/9.jpg'
import slider10 from './../../assets/img/slider/10.jpg'
import slider11 from './../../assets/img/slider/11.jpg'

const Slider = () => {
    const {t} = useTranslation()
    const location = useLocation()

    const data = [
        {
            id: 19274,
            img: slider1
        },
        {
            id: 19275,
            img: slider2
        },
        {
            id: 19276,
            img: slider3
        },
        {
            id: 19234,
            img: slider4
        },
        {
            id: 192746,
            img: slider5
        },
        {
            id: 192476,
            img: slider6
        },
        {
            id: 129276,
            img: slider7
        },
        
        {
            id: 1209276,
            img: slider8
        },
        {
            id: 1923476,
            img: slider9
        },
        {
            id: 1927306,
            img: slider10
        },
        {
            id: 1934306,
            img: slider11
        }
    ]

    return (
        <section className="slider d-flex align-items-center" id="home" >
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6 d-flex align-items-center'>
                        <div className='slider__text'>
                            <h2 className='slider__text--title' data-aos="fade-up" data-aos-duration="600">{t('slider_title')}</h2>
                            <h1 className='slider__text--desc' data-aos="fade-up" data-aos-duration="1000">{t('slider_desc')}</h1>
                            <div data-aos="fade-up" data-aos-duration="600">
                                <ContactBtn />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='slider--in'>

                            <Swiper 
                                modules={[Pagination, A11y, Autoplay]}
                                spaceBetween={30}
                                pagination={{ clickable: true }}
                                slidesPerView={1}
                                centeredSlides={true}
                                autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                                }}
                                >
                                {
                                    data.map(item => {
                                        return (
                                            <SwiperSlide key={item.id}>
                                                <figure className='slider__cover'  data-aos="fade-left" data-aos-duration="1000">
                                                    <img 
                                                        src={item.img} 
                                                        className='slider__cover--img' 
                                                        id='cover-img' 
                                                        alt='img' />
                                                </figure>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Slider