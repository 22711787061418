import './Panel.scss'

function AddRemoveInputField({setGallery, gallery}){

    // const [inputFields, setInputFields] = useState([{
    //     gallery:'',

    // } ]);
 
    const addInputField = ()=>{

        setGallery([...gallery, {
            gallery:'',
        } ])
      
    }
    const removeInputFields = (index)=>{
        const rows = [...gallery];
        rows.splice(index, 1);
        setGallery(rows);
   }
   const handleChange = (index, evnt)=>{
    
    const { name, value } = evnt.target;
    const list = [...gallery];
    list[index][name] = value;
    setGallery(list);
    
 
 
}
    return(
    
        <div className="container">
            <div className="row">
                <div className="col-sm-8">
                  {
                      gallery && gallery.map((data, index)=>{
                          const {gallery, emailAddress, salary}= data;
                          return(
                            <div className="row my-3" key={index}>
                    <div className="col">
                    <div className="form-group">
                    <input type="text" onChange={(evnt)=>handleChange(index, evnt)} value={gallery} name="gallery" className="form-control"  placeholder="Gallery" />
                    </div>
                    </div>
                   
                    <div className="col">
                

                
                 {(gallery && gallery.length!==1)? <div className="btn btn-outline-danger" onClick={removeInputFields}>x</div>:''}
                  
                 
                    </div>
                  </div>
                          )
                      })
                  }
     
                <div className="row">
                    <div className="col-sm-12">

                    <div className="btn btn-outline-success " onClick={addInputField}>Add New</div>
                    </div>
                </div>
                  </div>
                </div>
                <div className="col-sm-4">

                </div>
            </div>
        
    )
}
export default AddRemoveInputField