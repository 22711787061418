
const ContactForm = () => {

    return (
        <form className="contact__form">
            <div className="row">
                <iframe src="https://join.antmotorsg.com/" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
        </form>
    )
}

export default ContactForm