import './Cars.scss'
import CarsSlide from './CarsSlide'

const Cars = () => {
    return (
        <section 
            className="cars d-flex align-items-center" 
            id="cars" 
            >
            <CarsSlide />
        </section>
    )
}

export default Cars