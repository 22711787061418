import PictogramsItem from "./PictogramsItem"
import './Pictograms.scss'
import Auction from "../Auction/Auction"
import symbol1 from './../../assets/img/pictogram/symbol-1.png'
import symbol2 from './../../assets/img/pictogram/symbol-2.png'
import symbol3 from './../../assets/img/pictogram/symbol-3.png'
import symbol4 from './../../assets/img/pictogram/symbol-4.png'
import symbol5 from './../../assets/img/pictogram/symbol-5.png'
import symbol6 from './../../assets/img/pictogram/symbol-6.png'
import symbol7 from './../../assets/img/pictogram/symbol-7.png'
import symbol8 from './../../assets/img/pictogram/symbol-8.png'
import { useTranslation } from "react-i18next"

const Pictograms = () => {
    const {t} = useTranslation();

    const data = [
        {
            id: 3931,
            icon: symbol1,
            title: `Run and Drive | ${t('condition_car.1')}`,
        },
        {
            id: 14332,
            icon: symbol2,
            title: `Enhanced Vehicle | ${t('condition_car.2')}`,
        },
        {
            id: 2498324,
            icon: symbol3,
            title: `Seller Certified | ${t('condition_car.3')}`
        },
        {
            id: 52322,
            icon: symbol4,
            title: `CrashedToys | ${t('condition_car.4')}`
        },
        {
            id: 49334,
            icon: symbol5,
            title: `Engine Start Program | ${t('condition_car.5')}`
        },
        {
            id: 20573,
            icon: symbol6,
            title: `Featured Vehicle | ${t('condition_car.6')}`
        },
        {
            id: 5329810,
            icon: symbol7,
            title: `Offsite Sales | ${t('condition_car.7')}`
        },
        {
            id: 234891,
            icon: symbol8,
            title: `FAST | ${t('condition_car.8')}`
        }
    ]
    return (
        <section className="pictograms" id="auction">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <Auction />
                    </div>
                    <div className="col-lg-6 col-12">
                        <h1 className="section-title" >{t('condition_title')}</h1>
                        <div className="pictograms__list">
                            {
                                data.map(item => {
                                    return (
                                        <PictogramsItem key={item.id} item={item} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>                
            </div>
        </section>
    )
}

export default Pictograms