import { useTranslation } from 'react-i18next'
import './Auction.scss'

const Auction = () => {
    const {t} = useTranslation()
    return (
        <>
        <h1 className="section-title">{t('auction_title')}</h1>
        <div className='auction'>
            <ol className="auction__body">
                <li>
                    {t('auction_body.1')}
                </li>
                <li>
                    {t('auction_body.2')}
                </li>
                <li>
                    {t('auction_body.3')}
                </li>
                <li>
                    {t('auction_body.4')}
                </li>
                <li>
                    {t('auction_body.5')}
                </li>
                <li>
                    {t('auction_body.6')}
                </li>
                <li>
                    {t('auction_body.7')}
                </li>
                <li>
                    {t('auction_body.8')}
                </li>
                <li>
                    {t('auction_body.9')}
                </li>
                <li>
                    {t('auction_body.10')}
                </li>
            </ol>  
        </div>
        </>
    )
}
export default Auction