import './Cars.scss'
import { useContext } from 'react'
import { CarsContext } from './CarsContext'
import { useParams } from 'react-router-dom'
import DetailSlider from './DetailSlider';
import DetailParametrs from './DetailParametrs';

const CarDetail = () => {
    const {carsData} = useContext(CarsContext)
    const {id} = useParams()

    return (
        <section className="cars__detail">
            <div className='container'>
                <div className='row'>
                    {
                        carsData && carsData.filter( item => item.id === id).map(item => {
                            return (
                                <div key={item.createdAt}>
                                    <DetailSlider gallery={item.gallery}/>
                                    <div className='cars__detail--head d-flex'>
                                        <h1 className='cars__detail--title'>
                                        {item.name}
                                        </h1>
                                        <p className='cars__detail--price'>
                                        {item.price}
                                        </p>
                                    </div>
                                    <div className='cars__detail--body'>
                                        <p className='cars__detail--body-text'>{item.description}</p>
                                    </div>
                                    <DetailParametrs data={item.parametrs} />
                                    <DetailParametrs data={item.comfort} />
                                    <DetailParametrs data={item.interior} />
                                    <DetailParametrs data={item.safety} />
                                    <DetailParametrs data={item.multimedia} />
                                    <DetailParametrs data={item.other} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default CarDetail