
const PictogramsItem = ({item}) => {

    return (
        <div className="pictograms__item">
            <figure className="pictograms__item--icon" >
                <img src={item.icon} alt="icon" />
            </figure>
            <span className="pictograms__item--title">{item.title}</span>
        </div>
    )
}

export default PictogramsItem