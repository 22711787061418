import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import phone from './../../../assets/img/icons/phone.svg'
import './ContactBtn.scss'

const ContactBtn = () => {
    const {t} = useTranslation()

    return (
        <NavLink className='contact-btn' to={'tel:+995511422799'}>
            <img src={phone} alt='icon' />
            {t('header_contact_btn')}
        </NavLink>
    )
}

export default ContactBtn