import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    menu_text_home: 'Home',
                    menu_text_weare: 'About Us',
                    menu_text_offer: 'Offer',
                    menu_text_cars: 'Automobile',
                    menu_text_services: 'Services',
                    menu_text_join: 'Join Us',
                    menu_text_contact: 'Contact',
                    menu_text_auction: 'Auction',
                    header_contact_btn: 'Call',

                    slider_title: 'Import automobile',
                    slider_desc: 'lowest price',
                    
                    weare_section_title: 'WHO WE ARE?',
                    weare_section_text1: `
                    ANT MOTORS GROUP company has been providing import of new and used cars from America, Canada, Japan and Korea for more than 15 years.
                    `,
                    weare_section_text2: `
                    Our experienced team provides open selection of your desired vehicle, complete history analysis and professional assessment of current conditions, purchase at the best price, transportation, vehicle delivery in any region and full auto service.
                    `,
                    
                    offer_section_title: 'What do we you offer?',
                    offer_item_title: {
                        1: 'Consultation',
                        2: 'Buy',
                        3: 'Funding',
                        4: 'Transportation',
                        5: 'Tow truck services',
                    },

                    offer_item_desc: {
                        1: 
                        `
                        Our experienced experts will choose the model of the car you want for free, convey and analyze the car's history and current conditions, and calculate the full costs.
                        `,
                        2: 
                        `
                        Your selected vehicle will be purchased at the most favorable price and the documentation will be prepared in the shortest possible time for the fastest transportation.
                        `,
                        3: 
                        `
                        ANT MOTORS GROUP together with partner leasing companies will finance the purchase and import of your dream car.
                        `,
                        4: 
                        `
                        Fast loading of the purchased vehicle, photo-video report during loading, full insurance and delivery of the vehicle in all regions.
                        `,
                        5: 
                        `
                        Our professional team will repair and restore your vehicle in the shortest possible time.
                        `,
                    },

                    services_section_title: 'Services',
                    services_item_title: {
                        1: 'Tow truck service',
                        2: 'Purchase auto parts',
                        3: 'Oil Change',
                        4: 'Repair of undercarriage parts ',
                        5: 'A modern robot',
                        6: 'Painting works ',
                    },

                    cars_section_title: 'Automobile',
                    cars_section_link: 'All Automobile',

                    join_section_title: 'Join Us',
                    join_section_text: {
                        1: `If you are a dealer or want to become one, ANT MOTORS GROUP offers the best conditions, fill out an application and get:`,
                        2: `🔻 Personal codes`,
                        3: `🔻 Access to all auctions`,
                        4: `🔻 Free Carfax`,
                        5: `🔻 The best transportation rates`,
                        6: `🔻 Internal transportation`,
                        7: `🔻 Realization in the local market`,
                        8: `Many other offers and news are waiting for you with us, contact us and become a member of the professional team !!!`,
                    },
                    join_button_text: 'JOIN US',
                    
                    office_addres: 'Moscow Ave #29, Tbilisi',
                    footer_copy: 'All Rights Reserved 2023',

                    auction_title: 'Auction',
                    auction_body: {
                        1: `We will select the best option of the model you want`,
                        2: `We will evaluate the conditions of the car`,
                        3: `We will check the history of the car`,
                        4: `We will estimate the value of the car according to the conditions`,
                        5: `We will buy at the price you want`,
                        6: `Documents will be prepared in the shortest period of time`,
                        7: `You will be notified of text and photo information about vehicle loading`,
                        8: `The vehicle will be fully insured`,
                        9: `Upon arrival in Georgia, transportation to the desired address will take place`,
                        10: `If desired, the car will be given a full auto service`,
                    },
                    condition_title: 'Vehicle Condition',
                    condition_car: {
                        1: `In running condition`,
                        2: `Doesn't start and doesn't drive`,
                        3: `Seller's guarantee`,
                        4: `Lot from the site of the CrashedToys company`,
                        5: `Starts but doesn't drive`,
                        6: `Popular ca`,
                        7: `Selling outside of the site`,
                        8: `Cars from franchise dealers`
                    },

                    carDetail: {
                        Manufacturer: 'Manufacturer',
                        Model: 'Model',
                        Year: 'Year',
                        Category: 'Category',
                        Mileage: 'Mileage',
                        FuelType: 'FuelType',
                        EngineVolume: 'EngineVolume',
                        Cylinders: 'Cylinders',
                        GearBoxType: 'GearBoxType',
                        DriveWheels: 'DriveWheels',
                        Doors: 'Doors',
                        Wheel: 'Wheel',
                        Color: 'Color',
                        VinCode: 'VinCode',

                        SteeringHydraulics: '',
                        OnBoardComputer: '',
                        AirConditioning: '',
                        ParkingControl: '',
                        RearViewCamera: '',
                        ElectricSideMirros: '',
                        ClimateControl: '',
                        CruiseControl: '',
                        Sunroof: '',
                        HeatedSeats: '',
                        MemorySeats: '',
                        ABS: '',
                        ESP: '',
                        CentralLocking: '',
                        AlarmSystem: '',
                        FogLamp: '',
                        CentralScreen: '',
                        AUX: '',
                        Bluetooth: '',
                        MultifunctionSteering: '',
                        Rims: '',
                        SpareTyre: '',
                        DidabledAccessible: '',
                    }
                }
            },
            ka: {
                translation: {
                    menu_text_home: 'მთავარი',
                    menu_text_weare: 'ჩვენს შესახებ',
                    menu_text_offer: 'შეთავაზება',
                    menu_text_cars: 'ავტომობილები',
                    menu_text_services: 'სერვისები',
                    menu_text_join: 'შემოგვიერთდი',
                    menu_text_auction: 'აუქციონი',
                    header_contact_btn: 'დაგვიკავშირდით',

                    slider_title: 'ჩამოიყვანე ავტომობილი',
                    slider_desc: 'ყველაზე დაბალ ფასად',

                    weare_section_title: 'ჩვენს შესახებ',
                    weare_section_text1: `
                    კომპანია ANT MOTORS GROUP 15 წელზე მეტია უზრუნველყოფს ახალი და მეორადი ავტომობილების იმპორტს ამერიკიდან , კანადიდან , იაპონიიდან და კორეიდან.
                    `,
                    weare_section_text2: `
                    ჩვენი გუნდი გამორჩეულია იმით რომ არაერთი აუქციონის გარდა გთავაზობთ სრულ ავტო სერვის საუკეთესო ფასად , თქვენ არ მოგიწევთ შეძენილი ავტომობილის განახლებაზე დროის და ზედმეტი თანხების კარგვა , ყველაფერს ჩვენი გუნდი მიხედავს თქვენთვის საუკეთესო შედეგის მისაღებად. 
                    `,

                    offer_section_title: 'რას გთავაზობთ?',
                    offer_item_title: {
                        1: 'კონსულტაცია',
                        2: 'შეძენა',
                        3: 'დაფინანსება',
                        4: 'ტრანსპორტირება',
                        5: 'ავტოსერვისი',
                    },
                    offer_item_desc: {
                        1: `
                        ჩვენი გამოცდილი ექსპერტები სრულიად უფასოდ შეგარჩევინებენ თქვენთვის სასურველი ავტომობილის მოდელს , გადმოგცემენ და  გაანალიზებენ სრულად ავტომობილის ისტორიას და მიმდინარე კონდიციებს , მოახდენენ კალკულაციას სრული ხარჯების .
                        `,
                        2: `
                        თქვენი შერჩეული ავტომობილის შეძენა მოხდება მაქსიმალურად ხელსაყრელ ფასად და დროის უმოკლეს ვადაში მომზადდება დოკუმენტაცია უსწრაფესად ტრანსპორტირებისთვის.
                        `,
                        3: `
                        ANT MOTORS GROUP პარტნიორ სალიზინგო კომპანიებთან ერთად დაგიფინანსებთ ოცნების ავტომობილის შეძენა-ჩამოყვანას.
                        `,
                        4: `
                        შეძენილი ავტომობილის ჩატვირთვა უსწრაფესად,  ფოტო-ვიდეო ანგარიში ჩატვირთვისას ,  სრული დაზღვევა და სატრანსპორტო საშუალების მიწოდება ყველა რეგიონში.
                        `,
                        5: `
                        ჩვენი პროფესიონალი გუნდი შეაკეთებს და განაახლებს თქვენს ავტომობილს დროის უმოკლეს ვადაში.
                        `,
                    },

                    services_section_title: 'სერვისები',
                    services_item_title: {
                        1: 'ევაკუატორით მომსახურება ',
                        2: 'ავტო ნაწილების შეძენა',
                        3: 'ზეთის შეცვლა',
                        4: 'სავალი ნაწილების შეკეთება ',
                        5: 'თანამედროვე რობოტი',
                        6: 'სამღებრო სამუშაოები ',
                    },

                    cars_section_title: 'ავტომობილები',
                    cars_section_link: 'ყველა ავტომობილი',

                    join_section_title: 'შემოუერთდი ჩვენს გუნდს',
                    join_section_text: {
                        1: `თუ ხართ დილერი ან გსურთ რომ გახდეთ,  
                        ANT MOTORS GROUP გთავაზობთ საუკეთესო 
                        პირობებს, შეავსე განაცხადი და მიიღე :`,
                        2: `🔻პირადი კოდები `,
                        3: `🔻წვდომა ყველა აუქციონზე`,
                        4: `🔻უფასო ქარფაქსი`,
                        5: `🔻ტრანსპორტირების საუკეთესო ტარიფები`,
                        6: `🔻შიდა ტრანსპორტირება`,
                        7: `🔻ადგილობრივ ბაზარზე რეალიზება`,
                        8: `სხვა მრავალი შეთავაზება და სიახლე გელოდებათ ჩვენთან , დაგვიკავშირდი და გახდი პროფესიონალი გუნდის წევრი !!!`,
                    },
                    join_button_text: 'შემოგვიერთდი',

                    office_addres: 'მოსკოვის გამზირი #29, თბილისი',
                    footer_copy: 'ყველა უფლება დაცულია 2023',

                    auction_title: 'აუქციონი',
                    auction_body: {
                        1: `შევარჩევთ თქვენთვის სასურველი მოდელის  საუკეთესო ვარიანტს `,
                        2: `შევაფასებთ ავტომობილის კონდიციების `,
                        3: `შევამოწმებთ ავტომობილის ისტორიას `,
                        4: `შევაფასებთ ავტომობილის  ღირებულებას კონდიციების მიხედვით`,
                        5: `შევიძენთ თქვენთვის სასურველ ფასში`,
                        6: `დროის უმოკლეს მონაკვეთში მოხდება დოკუმენტების გამზადება`,
                        7: `გეცნობებათ ტექსტური და ფოტო ინფორმაცია ავტომობილის ჩატვირთვის`,
                        8: `მოხდება ავტომობილის სრული დაზღვევა`,
                        9: `საქართველოში ჩამოსვლისთანავე მოხდება  ტრანსპორტირება სასურველ მისამართზე `,
                        10: `სურვილისამებრ ავტომობილს ჩაუტარდება  სრული ავტო სერვისი`,
                    },
                    condition_title: 'ავტომობილის მდგომარეობა',
                    condition_car: {
                        1: `იქოქება და დადის`,
                        2: `არ იქოქება და არ დადის`,
                        3: `გამყიდველის გარანტია`,
                        4: `ლოტი კომპანია CrashedToys მოედნიდან`,
                        5: `იქოქება, მაგრამ არ დადის`,
                        6: `პოპულარული ავტომობილი`,
                        7: `გაყიდვა მოედნის ფარგლებს გარეთ`,
                        8: `ვტომობილები ფრანშიზის დილერებისგან`
                    },

                    carDetail: {
                        Manufacturer: 'მწარმოებელი',
                        Model: 'მოდელი',
                        Year: 'წელი',
                        Category: 'კატეგორია',
                        Mileage: 'გარბენი',
                        FuelType: 'საწვავის ტიპი',
                        EngineVolume: 'ძრავის მოცულობა',
                        Cylinders: 'Cylinders',
                        GearBoxType: 'GearBoxType',
                        DriveWheels: 'DriveWheels',
                        Doors: 'Doors',
                        Wheel: 'Wheel',
                        Color: 'Color',
                        VinCode: 'VinCode',

                        SteeringHydraulics: 'ჰიდრავლიკა',
                        OnBoardComputer: 'ბორტკომპიუტერი',
                        AirConditioning: 'კონდიციონერი',
                        ParkingControl: 'პარკინგკონტროლი',
                        RearViewCamera: 'უკანა ხედვის კამერა',
                        ElectricSideMirros: 'ელექტრო შუშები',
                        ClimateControl: 'კლიმატკონტროლი',
                        CruiseControl: 'კრუიზ-კონტროლი',

                        Sunroof: 'ლუქი',
                        HeatedSeats: 'სავარძლის გათბობა',
                        MemorySeats: 'სავარძლის მეხსიერება',

                        ABS: 'ABS',
                        ESP: 'მოცურების საწინააღმდეგო სისტემა',
                        CentralLocking: 'ცენტრალური საკეტი',
                        AlarmSystem: 'სიგნალიზაცია',
                        FogLamp: 'სანისლე ფარები',
                        
                        CentralScreen: 'მონიტორი (ნავიგაცია)',
                        AUX: 'AUX',
                        Bluetooth: 'Bluetooth',
                        MultifunctionSteering: 'მულტი საჭე',

                        Rims: 'დისკები',
                        SpareTyre: 'სათადარიგო საბურავი',
                        DidabledAccessible: 'სსმპ ადაპტირებული',
                    }
                }
            },
            ru: {
                translation: {
                    menu_text_home: 'Дом',
                    menu_text_weare: 'О нас',
                    menu_text_offer: 'Предложение',
                    menu_text_cars: 'Автомобиль',
                    menu_text_services: 'Услуги',
                    menu_text_join: 'Присоединяйтесь к нам',
                    menu_text_contact: 'Контакт',
                    menu_text_auction: 'Аукцион',
                    header_contact_btn: 'Вызов',

                    slider_title: 'Импорт автомобиль',
                    slider_desc: 'низшая цена',
                    
                    weare_section_title: 'КТО МЫ?',
                    weare_section_text1: `
                    Компания ANT MOTORS GROUP уже более 15 лет обеспечивает импорт новых и подержанных автомобилей из Америки, Канады, Японии и Кореи.
                    `,
                    weare_section_text2: `
                    Наша опытная команда обеспечивает открытый подбор желаемого автомобиля, полный анализ истории и профессиональную оценку текущего состояния, покупку по оптимальной цене, транспортировку, доставку автомобиля в любой регион и полный автосервис.
                    `,
                    
                    offer_section_title: 'Что мы предлагаем вам?',
                    offer_item_title: {
                        1: 'Консультация',
                        2: 'Купить',
                        3: 'Funding',
                        4: 'Финансирование',
                        5: 'Услуги эвакуатора',
                    },

                    offer_item_desc: {
                        1: 
                        `
                        Наши опытные специалисты бесплатно подберут нужную вам модель автомобиля, передадут и проанализируют историю автомобиля и текущее состояние, рассчитают полную стоимость.
                        `,
                        2: 
                        `
                        Выбранный вами автомобиль будет приобретен по максимально выгодной цене, а документация будет оформлена в кратчайшие сроки для максимально быстрой перевозки.
                        `,
                        3: 
                        `
                        ANT MOTORS GROUP совместно с партнерскими лизинговыми компаниями профинансирует покупку и импорт автомобиля вашей мечты.
                        `,
                        4: 
                        `
                        Быстрая загрузка купленного автомобиля, фото-видео отчет при загрузке, полная страховка и доставка автомобиля во все регионы.
                        `,
                        5: 
                        `
                        Наша профессиональная команда отремонтирует и восстановит ваш автомобиль в кратчайшие сроки.
                        `,
                    },

                    services_section_title: 'Услуги',
                    services_item_title: {
                        1: 'Услуги эвакуатора',
                        2: 'купить автозапчасти',
                        3: 'Замена масла',
                        4: 'Ремонт деталей ходовой части',
                        5: 'Современный робот',
                        6: 'Малярные работы',
                    },

                    cars_section_link: 'Все автомобили',
                    cars_section_title: 'Автомобиль',

                    join_section_title: 'Присоединяйтесь к нам',
                    join_section_text: {
                        1: `Если вы являетесь дилером или хотите им стать, ANT MOTORS GROUP предлагает лучшие условия, заполните заявку и получите:`,
                        2: `🔻 Персональные коды`,
                        3: `🔻 Доступ ко всем аукционам`,
                        4: `🔻 Бесплатный Карфакс`,
                        5: `🔻 Лучшие тарифы на перевозки`,
                        6: `🔻Внутренний транспорт`,
                        7: `🔻Реализация на местном рынке`,
                        8: `Много других предложений и новостей ждут вас вместе с нами, свяжитесь с нами и станьте членом команды профессионалов!!!`,
                    },
                    join_button_text: 'Присоединяйтесь к нам',
                    
                    office_addres: 'Московский пр. #29, Тбилиси',
                    footer_copy: 'Все права защищены 2023',

                    auction_title: 'аукцион',
                    auction_body: {
                        1: `Мы подберем оптимальный вариант нужной вам модели`,
                        2: `Оценим состояние автомобиля`,
                        3: `Мы проверим историю автомобиля`,
                        4: `Оценим стоимость автомобиля по условиям`,
                        5: `Мы купим по цене, которую вы хотите`,
                        6: `Документы будут подготовлены в кратчайшие сроки`,
                        7: `Вы будете уведомлены текстовой и фотоинформацией о загрузке автомобиля`,
                        8: `Автомобиль будет полностью застрахован`,
                        9: `По прибытии в Грузию будет организована перевозка по нужному адресу`,
                        10: `При желании автомобиль будет предоставлен в полный автосервис`,
                    },
                    condition_title: 'Состояние автомобиля',
                    condition_car: {
                        1: `Заводится и едет`,
                        2: `Не заводится и не едет`,
                        3: `Гарантия продавца`,
                        4: `Лот с площадки компании CrashedToys`,
                        5: `Заводится, но не едет`,
                        6: `Популярный автомобиль`,
                        7: `Продажа за пределами площадки`,
                        8: `Автомобили от дилеров франшизы`
                    },

                    carDetail: {
                        Manufacturer: 'Manufacturer',
                        Model: 'Model',
                        Year: 'Year',
                        Category: 'Category',
                        Mileage: 'Mileage',
                        FuelType: 'FuelType',
                        EngineVolume: 'EngineVolume',
                        Cylinders: 'Cylinders',
                        GearBoxType: 'GearBoxType',
                        DriveWheels: 'DriveWheels',
                        Doors: 'Doors',
                        Wheel: 'Wheel',
                        Color: 'Color',
                        VinCode: 'VinCode',

                        SteeringHydraulics: '',
                        OnBoardComputer: '',
                        AirConditioning: '',
                        ParkingControl: '',
                        RearViewCamera: '',
                        ElectricSideMirros: '',
                        ClimateControl: '',
                        CruiseControl: '',
                        Sunroof: '',
                        HeatedSeats: '',
                        MemorySeats: '',
                        ABS: '',
                        ESP: '',
                        CentralLocking: '',
                        AlarmSystem: '',
                        FogLamp: '',
                        CentralScreen: '',
                        AUX: '',
                        Bluetooth: '',
                        MultifunctionSteering: '',
                        Rims: '',
                        SpareTyre: '',
                        DidabledAccessible: '',
                    }
                }
            }
        },
        lng: "ka",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });
