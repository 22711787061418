import { useTranslation } from 'react-i18next'
import SectionTitle from '../Common/SectionTitle/SectionTitle'
import './Join.scss'
import Contact from '../Contact/Contact'
import ContactForm from '../Contact/ContactForm'

function icon(color){
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill={color} d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
        </>
    )
}

const JoinPage = () => {
    const {t} = useTranslation()

    return (
        <section className='join'>
            <div className='container'>
                <ContactForm />
            </div>
        </section>
    )
}

export default JoinPage