import './Footer.scss'
import logo from './../../../assets/img/logo.png'
import { useTranslation } from 'react-i18next'
import ContactList from '../../Contact/ContactList'

const Footer = () => {
    const {t} = useTranslation()
    return (
        <footer className='footer'>
            <div className='container'>
                <div className="row flex-wrap-reverse flex-lg-wrap">
                    <div className="col-12 col-md-3 col-lg-3">
                        <div>
                            <img src={logo} alt='logo' className='contact__logo' />
                            <p className="copy">{t('footer_copy')}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-9 col-lg-9">
                        <ContactList />
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer