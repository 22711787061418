import { useTranslation } from 'react-i18next'
import './JoinSection.scss'
import { NavLink } from 'react-router-dom'
import cover from './../../assets/img/join.png'

function icon() {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M0 55.2V426c0 12.2 9.9 22 22 22c6.3 0 12.4-2.7 16.6-7.5L121.2 346l58.1 116.3c7.9 15.8 27.1 22.2 42.9 14.3s22.2-27.1 14.3-42.9L179.8 320H297.9c12.2 0 22.1-9.9 22.1-22.1c0-6.3-2.7-12.3-7.4-16.5L38.6 37.9C34.3 34.1 28.9 32 23.2 32C10.4 32 0 42.4 0 55.2z"/></svg>
}


const JoinSection = () => {
    const {t} = useTranslation()

    return (
        <section className='join-section d-flex align-items-center' id="join">
            <div className='container'>
                <h1 className='join-section__title'>
                    <NavLink to={'/join'}>
                        {t('join_button_text')}
                        {icon()}
                    </NavLink>
                </h1>
                <div
                    style={{backgroundImage: `url(${cover})` }}
                    className='join-section__body' data-aos="fade-up" data-aos-duration="1000">
                    <p>{t('join_section_text.1')}</p>
                    <p>{t('join_section_text.2')}</p>
                    <p>{t('join_section_text.3')}</p>
                    <p>{t('join_section_text.4')}</p>
                    <p>{t('join_section_text.5')}</p>
                    <p>{t('join_section_text.6')}</p>
                    <p>{t('join_section_text.7')}</p>
                    <p>{t('join_section_text.8')}</p>
                    
                    
                </div>
            </div>
        </section>
    )
}

export default JoinSection