import { useTranslation } from 'react-i18next'
import './TextInput.scss'

const TextInput = ({type, placeholder, value, className, onChange, name}) => {
    const {t} = useTranslation()

    return (
        <label className={`input--out`}>
            {name && <p>{t('carDetail.' + name)}</p>}
            <input 
                type={type} 
                placeholder={placeholder} 
                value={value} 
                name={name}
                className={`${className ? className : 'input'}`}
                onChange={e => onChange(e.target.value)}
                />
        </label>
    )
}

export default TextInput