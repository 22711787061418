import './Services.scss'
import ServicesList from "./ServicesList"

const Services = () => {

    return (
        <section 
            className="services d-flex align-items-center" 
            id="services" 
            >
            <ServicesList />
        </section>
    )
}

export default Services