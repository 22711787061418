import { useDispatch, useSelector } from 'react-redux'
import '../Header.scss'
import { globalVariableAction } from '../../../../store/globalVariable'
import { useTranslation } from 'react-i18next'
import ContactBtn from '../ContactBtn'
import ka from './../../../../assets/img/lang/georgia.png'
import en from './../../../../assets/img/lang/united-kingdom.png'
import ru from './../../../../assets/img/lang/russia.png'
import { useState } from 'react'

const Lang = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {language} = useSelector(state => state.globalVariable)
    const [lang, setLang] = useState(false)


    function langFlag() {
        if(language === 'ka') {
            return ka
        }

        if(language === 'ru') {
            return ru
        }

        if(language === 'en') {
            return en
        }
    }

    function langChange(e) {
        dispatch(globalVariableAction.changeLanguage(e.target.attributes.value.value))
        setLang(false)
    }

    return (
        <div className='header__lang--out d-flex align-items-center justify-content-end'>
            <ContactBtn />
            <div className='header__lang'>
                <div className='header__lang--btn'  onClick={() => setLang(!lang)}>
                    <img src={langFlag()} alt='icon' />
                </div>
                {
                    lang && (
                        <div className='header__lang--list'>
                            <div className='header__lang--item' onClick={langChange}>
                                <img src={ka} alt='icon' value="ka" />
                            </div>
                            <div className='header__lang--item' onClick={langChange}>
                                <img src={en} alt='icon' value="en"/>
                            </div>
                            <div className='header__lang--item' onClick={langChange}>
                                <img src={ru} alt='icon' value="ru"/>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Lang