import OfferList from './OfferList'
import './Services.scss'

const Offer = () => {
    return (
        <section className="services d-flex align-items-center" id="offer">
            <OfferList />
        </section>
    )
}

export default Offer