import { useTranslation } from "react-i18next"
import ServiceItem from "./Item"
import './Services.scss'
import SectionTitle from "../Common/SectionTitle/SectionTitle"
import bg1 from './../../assets/img/offer/consultation.jpeg'
import bg2 from './../../assets/img/offer/shedzena.jpeg'
import bg3 from './../../assets/img/offer/dafinanseba.jpeg'
import bg4 from './../../assets/img/offer/transportireba.jpeg'
import bg5 from './../../assets/img/offer/servisi.jpeg'

const OfferList = () => {
    const {t} = useTranslation()

    const data = [
        {
            id: 1,
            title: t(`offer_item_title.1`),
            desc: t('offer_item_desc.1'),
            bg: bg1,
        },
        {
            id: 2,
            title: t('offer_item_title.2'),
            desc: t('offer_item_desc.2'),
            bg: bg2,
        },
        {
            id: 3,
            title: t('offer_item_title.3'),
            desc: t('offer_item_desc.3'),
            bg: bg3,
        },
        {
            id: 4,
            title: t('offer_item_title.4'),
            desc: t('offer_item_desc.4'),
            bg: bg4,
        },
        {
            id: 5,
            title: t('offer_item_title.5'),
            desc: t('offer_item_desc.5'),
            bg: bg5,
        },
    ]

    const Item = (item, id) =>{
        if(id === 0 || id === 1) {
            return (
                <div className="col-12 col-md-6 col-lg-6 d-flex" key={item.id} >
                    <ServiceItem item={item} />
                </div>
            )
        }
        return (
            <div className="col-12 col-md-6 col-lg-4 d-flex" key={item.id} >
                <ServiceItem item={item} />
            </div>
        )
    }

    return (
        <div className="container">
            <SectionTitle title={t('offer_section_title')}/>
            <div className="row">
                {
                    data.map((item, id) => {
                        return (
                            Item(item,id)
                        )
                    })
                }
            </div>
        </div>
    )
}

export default OfferList