import './Cars.scss'
import CarsItem from './Item'
import car1 from './../../assets/img/car1.png'
import car2 from './../../assets/img/car2.png'
import car3 from './../../assets/img/car3.png'
import { getData } from '../../http/ApiMethod'
import { useContext } from 'react'
import { CarsContext } from './CarsContext'

const CarsPage = () => {
    const {carsData} = useContext(CarsContext)

    return (
        <section className="cars__page">
            <div className='container'>
                <div className='row'>
                    {
                        carsData && carsData.map(item => {
                            return (
                                <div className='col-12 col-md-6 col-lg-4' key={item.id}>
                                    <CarsItem item={item} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default CarsPage