import { NavLink } from 'react-router-dom'
import './Cars.scss'

const CarsItem = ({item}) => {
    return (
        <NavLink to={`/cars/${item.id}`} className='cars__item'>
            <figure className='cars__item--cover d-flex align-items-center justify-content-center'>
                <img src={item.image} alt='icon' className='cars__item--img' />
                {/* <img src={`data:image/jpeg;base64,${item.image}`} alt='icon' className='cars__item--img'  /> */}
            </figure>
            <h1 className='cars__item--title'>{item.name}</h1>
            <p className='cars__item--price'>{item.price}</p>
        </NavLink>
    )
}

export default CarsItem