import { createContext, useEffect, useState } from "react"
import { getData } from "../../http/ApiMethod";

const CarsContext = createContext();

function CarsContextProvider({children}) {
    const [carsData, setCarsData] = useState(null);

    useEffect(() => {
        getData('cars').then(res => {
            const data = res?.data
            setCarsData(data && data)
        })
    }, [])

    return (
        <CarsContext.Provider value={{carsData: carsData}} >
            {children}
        </CarsContext.Provider>
    )
}

export {CarsContext, CarsContextProvider}