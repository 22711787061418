import './Services.scss'

const ServiceItem = ({item}) => {
    return (
        <div 
            className={`services__item`}
            data-aos="fade-up" 
            data-aos-duration="1000" 
            style={{backgroundImage: `url(${item.bg && item.bg})`, backgroundSize: 'cover'}}
            >
                <div className='services__item--text'>
                    <h1 className='services__item--title'>{item.title}</h1>
                    {item.desc && <p className='services__item--desc'>{item.desc}</p>}
                </div>
        </div>
    )
}

export default ServiceItem