import './Cars.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const DetailSlider = ({gallery}) => {

    return (
        <div className='cars__detail--slider'>
            <Swiper 
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={30}
                navigation
                pagination={{ clickable: true }}
                breakpoints={{
                    768: {
                    slidesPerView: 1,
                    },
                    1024: {
                        slidesPerView: 1,
                    },
                }}
                >
                {
                    gallery && gallery.map((item,id) => {
                        console.log(item)
                        return (
                            <SwiperSlide key={item+id}>
                                <figure className='cars__detail--images'>
                                    <img src={item.gallery} alt='cover' />
                                </figure>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>           
        </div>
    )
}

export default DetailSlider