import { useTranslation } from 'react-i18next'
import './Cars.scss'

const DetailParametrs = ({data}) => {
    const {t} = useTranslation()

    function itemValue(item){
        if(item.value == 'true'){
            return <div className='true'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg></div>
        }else if(item.value == 'false'){
            return <div className='false'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg></div>
        }else {
            return item.value
        }
    }

    return (
        <div className='cars__detail--body'>
            {
                data && data.map((item, id) => {
                    return (
                        <li key={item.title + id} className='cars__detail--table-item d-flex'>
                            <span className='cars__detail--table-title col-4'>
                                {t('carDetail.' + item.title)}
                            </span>
                            <span className='cars__detail--table-value col-8'> 
                                {itemValue(item)}
                            </span>
                        </li>
                    )
                })
            }
        </div>
    )
}

export default DetailParametrs