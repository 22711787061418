import { createSlice } from '@reduxjs/toolkit'

const initialGlobalVariable = {
    language: 'ka',
    cars: null,
}

const globalVariable = createSlice({
    name: 'user',
    initialState: initialGlobalVariable,
    reducers: {
        changeLanguage(state, action) {
            state.language = action.payload
        },
        changeCarsData(state, action) {
            state.cars = action.payload
        }
    }
})

export const globalVariableAction = globalVariable.actions;

export default globalVariable.reducer;