import { useState } from "react"
import TextInput from "../TextInput/TextInput"
import './Panel.scss'
import { sendData } from "../../http/ApiMethod"
import AddRemoveInputField from "./AddRemoveInputField"

const Panel = () => {
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    // const [gallery, setGallery] = useState([])
    const [gallery, setGallery] = useState([{
        gallery:'',
    } ])
    const [Manufacturer, setManufacturer] = useState('')
    const [Model, setModel] = useState('')
    const [Year, setYear] = useState('')
    const [Category, setCategory] = useState('')
    const [Mileage, setMileage] = useState('')
    const [FuelType, setFuelType] = useState('')
    const [EngineVolume, setEngineVolume] = useState('')
    const [Cylinders, setCylinders] = useState('')
    const [GearBoxType, setGearBoxType] = useState('')
    const [DriveWheels, setDriveWheels] = useState('')
    const [Doors, setDoors] = useState('')
    const [Wheel, setWheel] = useState('')
    const [Color, setColor] = useState('')
    const [VinCode, setVinCode] = useState('')

    const [SteeringHydraulics, setSteeringHydraulics] = useState('false')
    const [OnBoardComputer, setOnBoardComputer] = useState('false')
    const [AirConditioning, setAirConditioning] = useState('false')
    const [ParkingControl, setParkingControl] = useState('false')
    const [RearViewCamera, setRearViewCamera] = useState('false')
    const [ElectricSideMirros, setElectricSideMirros] = useState('false')
    const [ClimateControl, setClimateControl] = useState('false')
    const [CruiseControl, setCruiseControl] = useState('false')

    const [Sunroof, setSunroof] = useState('false') 
    const [HeatedSeats, setHeatedSeats] = useState('false') 
    const [MemorySeats, setMemorySeats] = useState('false') 

    const [ABS, setABS] = useState('false') 
    const [ESP, setESP] = useState('false') 
    const [CentralLocking, setCentralLocking] = useState('false') 
    const [AlarmSystem, setAlarmSystem] = useState('false') 
    const [FogLamp, setFogLamp] = useState('false') 

    const [CentralScreen, setCentralScreen] = useState('false') 
    const [AUX, setAUX] = useState('false') 
    const [Bluetooth, setBluetooth] = useState('false') 
    const [MultifunctionSteering, setMultifunctionSteering] = useState('false') 

    const [Rims, setRims] = useState('false') 
    const [SpareTyre, setSpareTyre] = useState('false') 
    const [DidabledAccessible, setDidabledAccessible] = useState('false') 

    const inputs = [
        {
            type: 'text', 
            name: 'name',
            placeholder: 'car nam',
            value: name,
            onChange: setName,
        },
        {
            type: 'text', 
            name: 'price',
            placeholder: 'car price',
            value: price,
            onChange: setPrice,
        },
        {
            type: 'text', 
            name: 'description',
            placeholder: 'description',
            value: description,
            onChange: setDescription,
        },
        {
            type: 'text', 
            name: 'image',
            placeholder: 'image',
            value: image,
            onChange: setImage,
        },
        {
            component: <AddRemoveInputField setGallery={setGallery} gallery={gallery}/>,
            type: 'text', 
            name: 'gallery',
            placeholder: 'gallery',
            value: gallery,
            onChange: setGallery,
        },
    ]

    const parametrs = [
        {
            type: 'text', 
            name: 'Manufacturer',
            placeholder: 'Manufacturer',
            value: Manufacturer,
            onChange: setManufacturer,
        },
        {
            type: 'text', 
            name: 'Model',
            placeholder: 'Model',
            value: Model,
            onChange: setModel,
        },
        {
            type: 'text', 
            name: 'Year',
            placeholder: 'Year',
            value: Year,
            onChange: setYear,
        },
        {
            type: 'text', 
            name: 'Category',
            placeholder: 'Category',
            value: Category,
            onChange: setCategory,
        },
        {
            type: 'text', 
            name: 'Mileage',
            placeholder: 'Mileage',
            value: Mileage,
            onChange: setMileage,
        },
        {
            type: 'text', 
            name: 'FuelType',
            placeholder: 'FuelType',
            value: FuelType,
            onChange: setFuelType,
        },
        {
            type: 'text', 
            name: 'EngineVolume',
            placeholder: 'EngineVolume',
            value: EngineVolume,
            onChange: setEngineVolume,
        },
        {
            type: 'text', 
            name: 'Cylinders',
            placeholder: 'Cylinders',
            value: Cylinders,
            onChange: setCylinders,
        },
        {
            type: 'text', 
            name: 'GearBoxType',
            placeholder: 'GearBoxType',
            value: GearBoxType,
            onChange: setGearBoxType,
        },
        {
            type: 'text', 
            name: 'DriveWheels',
            placeholder: 'DriveWheels',
            value: DriveWheels,
            onChange: setDriveWheels,
        },
        {
            type: 'text', 
            name: 'Doors',
            placeholder: 'Doors',
            value: Doors,
            onChange: setDoors,
        },
        {
            type: 'text', 
            name: 'Wheel',
            placeholder: 'Wheel',
            value: Wheel,
            onChange: setWheel,
        },
        {
            type: 'text', 
            name: 'Color',
            placeholder: 'Color',
            value: Color,
            onChange: setColor,
        },
        {
            type: 'text', 
            name: 'VinCode',
            placeholder: 'VinCode',
            value: VinCode,
            onChange: setVinCode,
        }
    ]

    const comforts = [
        {
            type: 'text', 
            name: 'SteeringHydraulics',
            placeholder: 'SteeringHydraulics',
            value: SteeringHydraulics,
            onChange: setSteeringHydraulics,
        },
        {
            type: 'text', 
            name: 'OnBoardComputer',
            placeholder: 'OnBoardComputer',
            value: OnBoardComputer,
            onChange: setOnBoardComputer,
        },
        {
            type: 'text', 
            name: 'AirConditioning',
            placeholder: 'AirConditioning',
            value: AirConditioning,
            onChange: setAirConditioning,
        },
        {
            type: 'text', 
            name: 'ParkingControl',
            placeholder: 'ParkingControl',
            value: ParkingControl,
            onChange: setParkingControl,
        },
        {
            type: 'text', 
            name: 'RearViewCamera',
            placeholder: 'RearViewCamera',
            value: RearViewCamera,
            onChange: setRearViewCamera,
        },
        {
            type: 'text', 
            name: 'ElectricSideMirros',
            placeholder: 'ElectricSideMirros',
            value: ElectricSideMirros,
            onChange: setElectricSideMirros,
        },
        {
            type: 'text', 
            name: 'ClimateControl',
            placeholder: 'ClimateControl',
            value: ClimateControl,
            onChange: setClimateControl,
        },
        {
            type: 'text', 
            name: 'CruiseControl',
            placeholder: 'CruiseControl',
            value: CruiseControl,
            onChange: setCruiseControl,
        },
    ]

    const interiors = [
        {
            type: 'text', 
            name: 'Sunroof',
            placeholder: 'Sunroof',
            value: Sunroof,
            onChange: setSunroof,
        },
        {
            type: 'text', 
            name: 'HeatedSeats',
            placeholder: 'HeatedSeats',
            value: HeatedSeats,
            onChange: setHeatedSeats,
        },
        {
            type: 'text', 
            name: 'MemorySeats',
            placeholder: 'MemorySeats',
            value: MemorySeats,
            onChange: setMemorySeats,
        },
    ]

    const safetys = [
        {
            type: 'text', 
            name: 'ABS',
            placeholder: 'ABS',
            value: ABS,
            onChange: setABS,
        },
        {
            type: 'text', 
            name: 'ESP',
            placeholder: 'ESP',
            value: ESP,
            onChange: setESP,
        },
        {
            type: 'text', 
            name: 'CentralLocking',
            placeholder: 'CentralLocking',
            value: CentralLocking,
            onChange: setCentralLocking,
        },
        {
            type: 'text', 
            name: 'AlarmSystem',
            placeholder: 'AlarmSystem',
            value: AlarmSystem,
            onChange: setAlarmSystem,
        },
        {
            type: 'text', 
            name: 'FogLamp',
            placeholder: 'FogLamp',
            value: FogLamp,
            onChange: setFogLamp,
        },
    ]

    const multimedias = [
        {
            type: 'text', 
            name: 'CentralScreen',
            placeholder: 'CentralScreen',
            value: CentralScreen,
            onChange: setCentralScreen,
        },
        {
            type: 'text', 
            name: 'AUX',
            placeholder: 'AUX',
            value: AUX,
            onChange: setAUX,
        },
        {
            type: 'text', 
            name: 'Bluetooth',
            placeholder: 'Bluetooth',
            value: Bluetooth,
            onChange: setBluetooth,
        },
        {
            type: 'text', 
            name: 'MultifunctionSteering',
            placeholder: 'MultifunctionSteering',
            value: MultifunctionSteering,
            onChange: setMultifunctionSteering,
        },
    ]

    const others = [
        {
            type: 'text', 
            name: 'Rims',
            placeholder: 'Rims',
            value: Rims,
            onChange: setRims,
        },
        {
            type: 'text', 
            name: 'SpareTyre',
            placeholder: 'SpareTyre',
            value: SpareTyre,
            onChange: setSpareTyre,
        },
        {
            type: 'text', 
            name: 'DidabledAccessible',
            placeholder: 'DidabledAccessible',
            value: DidabledAccessible,
            onChange: setDidabledAccessible,
        }
    ]

    const data = {
        name: name,
        price: price,
        description: description,
        image: image,
        gallery: gallery,
        parametrs: [
            {
                "title": 'Manufacturer',
                "value": Manufacturer
            },
            {
                "title": "Model",
                "value": Model
            },
            {
                "title": "Year",
                "value": Year
            },
            {
                "title": "Category",
                "value": Category
            },
            {
                "title": "Mileage",
                "value": Mileage
            },
            {
                "title": "FuelType",
                "value": FuelType
            },
            {
                "title": "EngineVolume",
                "value": EngineVolume
            },
            {
                "title": "Cylinders",
                "value": Cylinders
            },
            {
                "title": "GearBoxType",
                "value": GearBoxType
            },
            {
                "title": "DriveWheels",
                "value": DriveWheels
            },
            {
                "title": "Wheel",
                "value": Wheel
            },
            {
                "title": "Color",
                "value": Color
            },
            {
                "title": "VinCode",
                "value": VinCode
            },
        ],
        comfort: [
            {
                "title": 'SteeringHydraulics',
                "value": SteeringHydraulics
            },
            {
                "title": 'OnBoardComputer',
                "value": OnBoardComputer
            },
            {
                "title": 'AirConditioning',
                "value": AirConditioning
            },
            {
                "title": 'ParkingControl',
                "value": ParkingControl
            },
            {
                "title": 'RearViewCamera',
                "value": RearViewCamera
            },
            {
                "title": 'ElectricSideMirros',
                "value": ElectricSideMirros
            },
            {
                "title": 'ClimateControl',
                "value": ClimateControl
            },
            {
                "title": 'CruiseControl',
                "value": CruiseControl
            },
        ],
        interior: [
            {
                "title": 'Sunroof',
                "value": Sunroof
            },
            {
                "title": 'HeatedSeats',
                "value": HeatedSeats
            },
            {
                "title": 'MemorySeats',
                "value": MemorySeats
            },
        ],
        safety: [
            {
                "title": 'ABS',
                "value": ABS
            },
            {
                "title": 'ESP',
                "value": ESP
            },
            {
                "title": 'CentralLocking',
                "value": CentralLocking
            },
            {
                "title": 'AlarmSystem',
                "value": AlarmSystem
            },
            {
                "title": 'FogLamp',
                "value": FogLamp
            },
        ],
        multimedia: [
            {
                "title": 'CentralScreen',
                "value": CentralScreen
            },
            {
                "title": 'AUX',
                "value": AUX
            },
            {
                "title": 'Bluetooth',
                "value": Bluetooth
            },
            {
                "title": 'MultifunctionSteering',
                "value": MultifunctionSteering
            }
        ],
        other: [
            {
                "title": 'Rims',
                "value": Rims
            },
            {
                "title": 'SpareTyre',
                "value": SpareTyre
            },
            {
                "title": 'DidabledAccessible',
                "value": DidabledAccessible
            }
        ]
    }

    function sendDataSubmit(e){
        e.preventDefault()

        if(name){
            sendData(`cars`, 'post', {
                ...data,
            }).then( res => {
                const respons = res.data 
            })
        }

    }

    return (
        <div className="panel">
            <div className="container">
                <form onSubmit={sendDataSubmit}>
                    <details className="panel__item">
                        <summary className="panel__item--head">
                            ძირითადი
                        </summary>
                        <div>
                        {
                            inputs.map(input => {
                                return (
                                    input.component ? input.component : <TextInput 
                                        key={input.name}
                                        type={input.type}
                                        name={input.name}
                                        placeholder={input.placeholder}
                                        value={input.value}
                                        onChange={input.onChange}
                                        />
                                )
                            })
                        }
                        </div>
                    </details>
                    <details className="panel__item">
                        <summary className="panel__item--head">
                            პარამეტრები
                        </summary>
                        <div className="row">
                            
                        {
                            parametrs.map(param => {
                                return (
                                    <div className="col-6" key={param.name}>
                                        <TextInput 
                                            type={param.type}
                                            name={param.name}
                                            placeholder={param.placeholder}
                                            value={param.value}
                                            onChange={param.onChange}
                                            />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </details>
                    <details className="panel__item">
                        <summary className="panel__item--head">
                            კომფორტი
                        </summary>
                        <div className="row">
                        {
                            comforts.map(comfort => {
                                return (
                                    <div className="col-4" key={comfort.name}>
                                        <TextInput 
                                            type={comfort.type}
                                            name={comfort.name}
                                            placeholder={comfort.placeholder}
                                            value={comfort.value}
                                            onChange={comfort.onChange}
                                            />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </details>
                    <details className="panel__item">
                        <summary className="panel__item--head">
                            სალონი
                        </summary>
                        <div className="row">
                        {
                            interiors.map(interior => {
                                return (
                                    <div className="col-4" key={interior.name}>
                                        <TextInput 
                                            type={interior.type}
                                            name={interior.name}
                                            placeholder={interior.placeholder}
                                            value={interior.value}
                                            onChange={interior.onChange}
                                            />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </details>
                    <details className="panel__item">
                        <summary className="panel__item--head">
                            უსაფრთხოება
                        </summary>
                        <div className="row">
                        {
                            safetys.map(safety => {
                                return (
                                    <div className="col-4" key={safety.name}>
                                        <TextInput 
                                            type={safety.type}
                                            name={safety.name}
                                            placeholder={safety.placeholder}
                                            value={safety.value}
                                            onChange={safety.onChange}
                                            />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </details>
                    <details className="panel__item">
                        <summary className="panel__item--head">
                            მულტიმედია
                        </summary>
                        <div className="row">
                        {
                            multimedias.map(multimedia => {
                                return (
                                    <div className="col-4" key={multimedia.name}>
                                        <TextInput 
                                            type={multimedia.type}
                                            name={multimedia.name}
                                            placeholder={multimedia.placeholder}
                                            value={multimedia.value}
                                            onChange={multimedia.onChange}
                                            />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </details>
                    <details className="panel__item">
                        <summary className="panel__item--head">
                            სხვადასხვა
                        </summary>
                        <div className="row">
                        {
                            others.map(other => {
                                return (
                                    <div className="col-4" key={other.name}>
                                        <TextInput 
                                            type={other.type}
                                            name={other.name}
                                            placeholder={other.placeholder}
                                            value={other.value}
                                            onChange={other.onChange}
                                            />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </details>
                <button>send</button>
                </form>
            </div>
        </div>
    )
}

export default Panel