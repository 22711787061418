import { useTranslation } from "react-i18next"
import './Cars.scss'
import SectionTitle from "../Common/SectionTitle/SectionTitle"
import CarsItem from "./Item"
import { NavLink } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { CarsContext } from "./CarsContext"
import { useContext } from "react"

const CarsSlide = () => {
    const {t} = useTranslation()
    const {carsData} = useContext(CarsContext)

    return (
        <div className="container">
            <div className="cars__container" data-aos="fade-up" data-aos-duration="1000">
                <div className="cars__head">
                    <SectionTitle title={t('cars_section_title')}/>  
                    <NavLink to={'/cars'} className={'cars__head--btn'}>
                        {t('cars_section_link')}
                    </NavLink>
                </div>
                <div className="cars__slide">
                    <Swiper 
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={30}
                        navigation
                        pagination={{ clickable: true }}
                        breakpoints={{
                            768: {
                              slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                        >
                        {
                            carsData && carsData.map((item, key) => {
                                if(key < 9) {
                                    return (
                                        <SwiperSlide key={item.id}>
                                            <CarsItem item={item} />
                                        </SwiperSlide>
                                    )
                                }
                            })
                        }
                    </Swiper>
                    {/* <div className="row">
                        {
                            data.map(item => {
                                return (
                                    <div className="col-3" key={item.id} >
                                        <CarsItem item={item} />
                                    </div>
                                )
                            })
                        }
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default CarsSlide