import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Header from './Components/Common/Header/Header';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home'
import { useTranslation } from 'react-i18next';
import 'aos/dist/aos.css';
import './App.scss';
import SocNetwork from './Components/Slider/SocNetwork';
import CarsPage from './Components/Cars/CarsPage';
import CarDetail from './Components/Cars/CarDetail';
import { CarsContextProvider } from './Components/Cars/CarsContext';
import Panel from './Components/Panel/Panel';
import JoinPage from './Components/Join/Join';
import Footer from './Components/Common/Footer/Footer';
    
function App() {
    const {i18n} = useTranslation()
    const {language} = useSelector(state => state.globalVariable)
    
    useEffect(()=>{
        i18n.changeLanguage(language)
    }, [language])

    return (
        <div className="App">
            <Header />
            <SocNetwork />
            <CarsContextProvider>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/join' element={<JoinPage />} />
                    <Route path='/cars' element={<CarsPage />} />
                    <Route path='/cars/:id' element={<CarDetail />} />
                    <Route path='/pnl' element={<Panel />} />
                </Routes>
            </CarsContextProvider>
            <Footer />
        </div>
    );

}

export default App;
