import { NavLink, useLocation } from 'react-router-dom'
import '../Header.scss'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

const Menu = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const [menu, setMenu] = useState('#home')
    const [clickScroll, setClickScroll] = useState(false)

    const handleClickScroll = (e) => {
        const element = document.querySelector(e.target.hash);
        setClickScroll(true)
        setTimeout(() => {
            setClickScroll(false)
        }, 1000);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const data = [
        {
            id: 10372,
            link: '#home',
            title: t('menu_text_home'),
            click: handleClickScroll,
        },
        {
            id: 20372,
            link: '#weare',
            title: t('menu_text_weare'),
            click: handleClickScroll,
        },
        {
            id: 30372,
            link: '#offer',
            title: t('menu_text_offer'),
            click: handleClickScroll,
        },
        {
            id: 2323432,
            link: '#auction',
            title: t('menu_text_auction'),
            click: handleClickScroll,
        },
        {
            id: 50372,
            link: '#services',
            title: t('menu_text_services'),
            click: handleClickScroll,
        },
        {
            id: 40372,
            link: '#cars',
            title: t('menu_text_cars'),
            click: handleClickScroll,
        },
        {
            id: 60372,
            link: '#join',
            title: t('menu_text_join'),
            click: handleClickScroll,
        }
    ]

    const homeSection = document.getElementById("home");
    const weareSection = document.getElementById("weare");
    const offerSection = document.getElementById("offer");
    const auctionSection = document.getElementById("auction");
    const servicesSection = document.getElementById("services");
    const carsSection = document.getElementById("cars");
    const joinSection = document.getElementById("join");

    window.onscroll = function (e) {
        let scroll = (window.pageYOffset + window.innerHeight / 2)
        
        if(window.pageYOffset > (window.innerHeight / 1.5)) {
            document.querySelector(".header").classList.add('fix');
        } else {
            document.querySelector(".header").classList.remove('fix')
        }

        if(!clickScroll && location.pathname === '/'){
            if(
                scroll > homeSection.offsetTop &&
                scroll < weareSection.offsetTop 
            ) {
                setSection('#home')
            }else if(
                scroll > weareSection.offsetTop &&
                scroll < offerSection.offsetTop 
            ) {
                setSection('#weare')
            }else if(
                scroll > offerSection.offsetTop &&
                scroll < auctionSection.offsetTop 
            ) {
                setSection('#offer')
            }else if(
                scroll > auctionSection.offsetTop &&
                scroll < servicesSection.offsetTop 
            ) {
                setSection('#auction')
            }else if(
                scroll > servicesSection.offsetTop &&
                scroll < carsSection.offsetTop 
            ) {
                setSection('#services')
            }else if(
                scroll > carsSection.offsetTop &&
                scroll < joinSection.offsetTop 
            ) {
                setSection('#cars')
            } else if(
                scroll > joinSection.offsetTop
            ) {
                setSection('#join')
            } else {
                setSection('#home')
            }
        }
    }

    useEffect(()=>{
        setMenu(window.location.hash)
    }, [location])

    const setSection = (name) => {
        setMenu(`${name}`)
        window.location.hash = `${name}`
    }

    
    return (
        <ul className='header__menu'>
            {
                data.map(item => {
                    return (
                        <li 
                            className='header__menu--item' 
                            key={item.id}
                            >
                            <NavLink 
                                to={location.pathname !== '/' ? `/${item.link}` : item.link} 
                                className={`header__menu--link ${menu === item.link && 'isactive'}`}
                                onClick={item.click}
                                >
                                {item.title}
                            </NavLink>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default Menu