import { useTranslation } from 'react-i18next'
import SectionTitle from '../Common/SectionTitle/SectionTitle'
import './Weare.scss'
import cover from './../../assets/img/weare-cover.png'
import JoinSection from '../JoinSection/JoinSection'

const Weare = () => {
    const {t} = useTranslation()

    return (
        <section 
            style={{backgroundImage: `url(${cover})`}}
            className='weare d-flex align-items-center' 
            id="weare"
            >
            <div className='container'> 
                <div className='weare__header'>
                    <SectionTitle title={t('weare_section_title')} />
                </div>
                <div className='weare__body' data-aos="fade-up" data-aos-duration="1200">
                    <p>{t('weare_section_text1')}</p>
                    <br />
                    <p>{t('weare_section_text2')}</p>
                </div>
            </div>

        </section>
    )
}

export default Weare