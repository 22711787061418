import axios from 'axios'
const API = process.env.REACT_APP_CARS_API;

async function getData(url) {
    try{
        const reset = await axios({
            method: 'get',
            url: API + url
        })
        return reset
    }catch (error){
        console.log(error)
    }
}

async function sendData(url, method, data) {
    try{
        const reset = await axios({
            baseURL: API,
            method: method,
            url: API + url,
            data: {
                ...data
            }
        })
        return reset
    }catch (error){
        console.log(error)
    }
}


export {getData, sendData}