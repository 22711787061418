import { useTranslation } from "react-i18next"
import ServiceItem from "./Item"
import './Services.scss'
import SectionTitle from "../Common/SectionTitle/SectionTitle"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Autoplay} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import slider1 from './../../assets/img/service/1.jpg'
import slider2 from './../../assets/img/service/2.jpg'
import slider3 from './../../assets/img/service/3.jpeg'
import slider4 from './../../assets/img/service/4.webp'
import slider5 from './../../assets/img/service/5.jpg'
import slider6 from './../../assets/img/service/6.jpg'

const ServicesList = () => {
    const {t} = useTranslation()
    const data = [
        {
            id: 1,
            icon: 'http://brokerapp.gelashvili.info/img/product-icon/icon1.svg',
            title: t('services_item_title.1'),
        },
        {
            id: 2,
            icon: 'http://brokerapp.gelashvili.info/img/product-icon/icon1.svg',
            title: t('services_item_title.2'),
        },
        {
            id: 3,
            icon: 'http://brokerapp.gelashvili.info/img/product-icon/icon1.svg',
            title: t('services_item_title.3'),
        },
        {
            id: 4,
            icon: 'http://brokerapp.gelashvili.info/img/product-icon/icon1.svg',
            title: t('services_item_title.4'),
        },
        {
            id: 5,
            icon: 'http://brokerapp.gelashvili.info/img/product-icon/icon1.svg',
            title: t('services_item_title.5'),
        },
        {
            id: 6,
            icon: 'http://brokerapp.gelashvili.info/img/product-icon/icon1.svg',
            title: t('services_item_title.6'),
        }
    ]

    const sliderData = [
        {
            id: 19274,
            img: slider1
        },
        {
            id: 19275,
            img: slider2
        },
        {
            id: 19276,
            img: slider3
        },
        {
            id: 19234,
            img: slider4
        },
        {
            id: 192746,
            img: slider5
        },
        {
            id: 192476,
            img: slider6
        }
    ]

    return (
        <div className="container">
            <div className="row flex-wrap-reverse flex-lg-wrap">
                <div className="col-lg-6">
                    <div className="services__slider--out">    
                        <div className="services__slider">
                            <Swiper 
                                modules={[Pagination, A11y, Autoplay]}
                                spaceBetween={30}
                                pagination={{ clickable: true }}
                                slidesPerView={1}
                                centeredSlides={true}
                                autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                                }}
                                >
                                {
                                    sliderData.map(item => {
                                        return (
                                            <SwiperSlide key={item.id}>
                                                <figure className='services__slider--cover'  data-aos="fade-left" data-aos-duration="1000">
                                                    <img 
                                                        src={item.img} 
                                                        className='services__slider--img' 
                                                        id='cover-img' 
                                                        alt='img' />
                                                </figure>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="services__list--out">
                        <ul className="services__list">
                            <SectionTitle title={t('services_section_title')}/>
                            {
                                data.map(item => {
                                    return (
                                        <li 
                                        key={item.id}
                                            className="services__list--item"
                                            data-aos="fade-up" 
                                            data-aos-duration="1000"
                                            >
                                                {item.title}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesList